exports.components = {
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-devices-device-details-js": () => import("./../../../src/pages/devices/deviceDetails.js" /* webpackChunkName: "component---src-pages-devices-device-details-js" */),
  "component---src-pages-devices-index-js": () => import("./../../../src/pages/devices/index.js" /* webpackChunkName: "component---src-pages-devices-index-js" */),
  "component---src-pages-devices-share-js": () => import("./../../../src/pages/devices/share.js" /* webpackChunkName: "component---src-pages-devices-share-js" */),
  "component---src-pages-employees-employees-info-js": () => import("./../../../src/pages/employees/employeesInfo.js" /* webpackChunkName: "component---src-pages-employees-employees-info-js" */),
  "component---src-pages-employees-index-js": () => import("./../../../src/pages/employees/index.js" /* webpackChunkName: "component---src-pages-employees-index-js" */),
  "component---src-pages-employees-tag-js": () => import("./../../../src/pages/employees/tag.js" /* webpackChunkName: "component---src-pages-employees-tag-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-payment-price-id-js": () => import("./../../../src/pages/payment/[priceID].js" /* webpackChunkName: "component---src-pages-payment-price-id-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */)
}

