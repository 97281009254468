import React from 'react'
import { useAuthState } from '../apis/use_aws_auth'
import { useStripeInfo } from '../apis/use_stripe_info'
import { useLoginEmployee } from '../apis/use_login_employee_info'
import { useCompanyHistory } from '../apis/use_company_history'
import { useCompanyDevice } from '../apis/use_company_device'
import { useCompanyEmployee } from '../apis/use_company_employee'
import { useCreditCard } from '../apis/use_credit_card'
import {useIotCtrl} from "../apis/use_iot_ctrl";

export const GlobalStateContext = React.createContext({})
const GlobalContextProvider = ({ children, location }) => {
  const gAuth = useAuthState()
  const gStripe = useStripeInfo(gAuth)

  const gLoginEmployee = useLoginEmployee(gStripe, gAuth)
  const gCompanyHistory = useCompanyHistory(gAuth)

    const gIot = useIotCtrl(gAuth)
  const gCompanyDevice = useCompanyDevice(gLoginEmployee, gAuth)
  const gCompanyEmployee = useCompanyEmployee(gLoginEmployee, gAuth)
  const gCreditCard = useCreditCard(gAuth, gLoginEmployee, gStripe)
  return (
    <GlobalStateContext.Provider
      value={{
        gAuth,
        gStripe,
        location,
        gLoginEmployee,
        gCompanyHistory,
        gCompanyDevice,
        gCompanyEmployee,
        gCreditCard,
          gIot,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  )
}
export default GlobalContextProvider
