const awsmobile = {
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_bY2byhlCa',
    userPoolWebClientId: '21u50hboia4s5q0sbk6pbdfmss',
    identityPoolId: 'ap-northeast-1:e5a1e548-564f-4681-bd5c-ec746ec9c684',
    mandatorySignIn: false,
  },
  API: {
    endpoints: [
      {
        region: 'ap-northeast-1',
        name: 'Attendance',
        endpoint: 'https://app.candyhouse.co/attendance',
      },


      {
        region: 'ap-northeast-1',
        name: 'newApi',
        endpoint: 'https://bbemppkf6c.execute-api.ap-northeast-1.amazonaws.com/v1',
      },
      {
        region: 'ap-northeast-1',
        name: 'SesameSDKPublic',
        endpoint: 'https://app.candyhouse.co/api',
      },
      {
        region: 'ap-northeast-2',
        name: 'Attendance_seoul',
        endpoint:
          'https://jvkjttpkhi.execute-api.ap-northeast-2.amazonaws.com/v1',
      },
      {
        region: 'ap-northeast-1',
        name: 'CandyHouseMobileAPI',
        endpoint:
            'https://jhcr1i3ecb.execute-api.ap-northeast-1.amazonaws.com/dev',
      },

    ],
  },
}
export default awsmobile
