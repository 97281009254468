import { useState, useEffect } from 'react'
import { API } from 'aws-amplify'

export const useCompanyDevice = (gLoginEmployee, gAuth) => {
  const init = {
    count: 0,
    Items: [],
  }
  const [devices, setDevices] = useState(init)
  const [selectedDevice, setSelectedDevice] = useState([])
  const [isPending, setIsPending] = useState(false)
  const getDevicesList = (companyID, access) => {
    setIsPending(true)
    API.get('Attendance', `/device`, {
      queryStringParameters: {
        companyID,
        access: JSON.stringify(access),
      },
    })
      .then((res) => {
        setDevices(res)
        setIsPending(false)



      })
      .catch((err) => console.log(err))
  }

  const reset = () => {
    setDevices(init)
    setSelectedDevice([])
    setIsPending(false)
  }
  useEffect(() => {
    if (gLoginEmployee.loginInfo.companyID) {
      getDevicesList(
        gLoginEmployee.loginInfo.companyID,
        gLoginEmployee.loginInfo.access,
      )
    }
  }, [gLoginEmployee.loginInfo.companyID])

  useEffect(() => {
    if (gAuth.loginState === 'signOut') {
      reset()
    }
  }, [gAuth.loginState])

  return {
    selectedDevice,
    setSelectedDevice,
    devices,
    setDevices,
    getDevicesList,
    isPending,
    setIsPending,
  }
}
