import awsmobile from './aws-exports'
import Amplify from 'aws-amplify'
import GlobalContextProvider from './src/context/GlobalContextProvider'
import React from 'react'
import { ThemeProvider } from '@mui/material'
import theme from './src/theme/theme'
import CssBaseline from '@mui/material/CssBaseline'
import './src/styles/global.css'
import cryptojs_extension from 'cryptojs-extension/build_node/cmac'
import {BannerCtrl} from "./src/components/banner";

export const wrapRootElement = ({ element }) => {
  Amplify.configure(awsmobile)
  return (
      <BannerCtrl>

          <GlobalContextProvider>
              <ThemeProvider theme={theme}>
                  <CssBaseline />
                  {element}
              </ThemeProvider>
          </GlobalContextProvider>

      </BannerCtrl>

  )
}
