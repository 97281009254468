import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { Buffer } from 'buffer'
export const useCompanyHistory = (gAuth) => {
  const [isPending, setIsPending] = useState(false)
  const [companyDevices, setCompanyDevices] = useState([])
  const [history, setHistory] = useState([])
  const [lastKey, setLastKey] = useState([])
  // const [ts_from, setTs_from] = useState(new Date().getTime())
  const reset = () => {
    setHistory([])
    setIsPending(false)
  }

  const handelGetDevices = async (companyID, historyCallback) => {
    setIsPending(true)
    await API.get('Attendance', `/history/device `, {
      queryStringParameters: {
        // companyID: 'cus_MnryRkG87mPQtF', //資料爆多的客人
        companyID,
      },
    })
      .then((res) => {
        // console.log('res', res)
        setCompanyDevices(res)
       // console.log("公司用户设备",res)

         /* let   list=res.map(item=>{
              return {deviceId:item.deviceUUID}
          })*/
          let   list=[]
          for (let i = 0; i < res.length; i++) {

              list.push({deviceUUID:res[i].deviceUUID,register_time:res[i].register_time})
          }
         handleGetHistory(new Date().getTime(), list, historyCallback)
         // getAllHistory(list)
      })
      .catch((err) => {
        console.log(err)
      })
  }




  const handleGetHistory = async (ts_from, devices, historyCallback) => {
  //  console.log('timestamp', new Date(ts_from), 'devices', devices);
    // 30 days
    let period = 2592000000;
    let deviceInfo = devices
        .filter((device) => ts_from > device.register_time)
        .map((device) => {
          device.ts_end = device.register_time > ts_from - period ? device.register_time : ts_from - period;
          device.ts_from = ts_from;
          return device;
        });

    // 分批处理设备，每批最多5个
    const batchProcess = async (batch) => {
      setIsPending(true);
      await API.get('Attendance', `/history`, {
        queryStringParameters: { device_info: JSON.stringify(batch) },
      })
          .then((res) => {

              console.log("请求到的历史数据",res)
            let histories = res
                .map((item) => {
                  item.result.map((history) => {
                    history.deviceName = item.device.deviceName;
                    if (history.history_tag) {
                      history.history_tag = Buffer.from(history.history_tag, 'base64').toString('utf8');
                    }
                    return history;
                  });
                  return item.result;
                })
                .flat(2)
                .sort((x, y) => y.timestamp - x.timestamp)
                .filter(
                    (history) =>
                        [1, 2, 6, 7, 8, 14, 15, 16, 17, 19, 20].includes(history.type),
                );
            setHistory((oldArr) => [...oldArr, ...histories]);
            setIsPending(false);
          })
          .catch((err) => {
            console.log(err);
          });
    };

    // 将deviceInfo分成多个批次，每批最多5个设备
    for (let i = 0; i < deviceInfo.length; i += 10) {
      const batch = deviceInfo.slice(i, i + 10);
      await batchProcess(batch);
    }
    if (historyCallback){
        historyCallback();
    }
    // 所有批次处理完成后调用回调


  };
/*
  const handleGetHistory = async (ts_from, devices, historyCallback) => {
    console.log('timestamp', new Date(ts_from), 'devices', devices)
    //30 days
    let period = 2592000000
    // console.log('進去device', devices)
    let deviceInfo = devices
      .filter((device) => ts_from > device.register_time)
      .map((device) => {
        device.ts_end =
          device.register_time > ts_from - period
            ? device.register_time
            : ts_from - period
        device.ts_from = ts_from
        return device
      })
    setCompanyDevices(deviceInfo)
    // console.log('deviceInfo', deviceInfo)
    setIsPending(true)

    await API.get('Attendance', `/history`, {
      // await API.get('Attendance_seoul', `/test_history`, {
      queryStringParameters: { device_info: JSON.stringify(deviceInfo) },
    })
      .then((res) => {
        // console.log('歷史=>', res)
        let histories = res
          .map((item) => {
            item.result.map((history) => {
              history.deviceName = item.device.deviceName
              // history.history_tag = Buffer.from(history.history_tag, 'base64').toString('utf8')

              if (history.history_tag) {
                history.history_tag = Buffer.from(
                  history.history_tag,
                  'base64',
                ).toString('utf8')
              }
              return history
            })
            return item.result
          })
          .flat(2)
          .sort(function (x, y) {
            return y.timestamp - x.timestamp
          })

        histories = histories.filter(
          (history) =>
            history.type === 1 ||
            history.type === 2 ||
            history.type === 6 ||
            history.type === 7 ||
            history.type === 8 ||
            history.type === 14 ||
            history.type === 15 ||
            history.type === 16 ||
            history.type === 17 ||
            history.type === 19 ||
            history.type === 20,
        )
        // console.log('histories', histories)
        setHistory((oldArr) => [...oldArr, ...histories])
        setIsPending(false)
      })
      .then(() => {
        historyCallback()
      })
      .catch((err) => {
        console.log(err)
      })
  }*/

  useEffect(() => {
    if (gAuth.loginState === 'signOut') {
      reset()
    }
  }, [gAuth.loginState])

  return {
    isPending,
    setIsPending,
    history,
    setHistory,
    handelGetDevices,
    handleGetHistory,
    companyDevices,
  }
}
