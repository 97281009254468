import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'

export const useCompanyEmployee = (gLoginEmployee, gAuth) => {
  const init = {
    count: 0,
    Items: [],
  }
  const [employees, setEmployees] = useState(init)
  const [isPending, setIsPending] = useState(false)

  const reset = () => {
    setEmployees(init)
    setIsPending(false)
  }

  const getEmployees = (companyID, access, getCount) => {
    setIsPending(true)
    API.get('Attendance', `/employee`, {
      queryStringParameters: {
        companyID,
        access: JSON.stringify(access),
      },
    })
      .then((res) => {
        setEmployees(res)
        setIsPending(false)
      })
      .then(() => {
        getCount()
      })
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    if (gLoginEmployee.loginInfo.companyID) {
      getEmployees(
        gLoginEmployee.loginInfo.companyID,
        gLoginEmployee.loginInfo.access,
        null,
      )
    }
  }, [gLoginEmployee.loginInfo.companyID])

  useEffect(() => {
    if (gAuth.loginState === 'signOut') {
      reset()
    }
  }, [gAuth.loginState])

  return {
    employees,
    setEmployees,
    getEmployees,
    isPending,
    setIsPending,
  }
}
