import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'

export const useCreditCard = (gAuth) => {
  const [creditCardList, setCreditCardList] = useState([])
  const [addCardSuccess, setAddCardSuccess] = useState('')
  const [openCardModal, setOpenCardModal] = useState(false)
  const [openCardModal2, setOpenCardModal2] = useState(false)
  const getCreditCard = (companyID) => {
    API.get('Attendance', `/credit_card`, {
      queryStringParameters: {
        customer: companyID,
      },
    })
      .then((res) => {
        setCreditCardList(res)
      })
      .then(() => {})
      .catch((err) => console.log(err))
  }

  const reset = () => {
    setCreditCardList([])
  }

  useEffect(() => {
    if (gAuth.loginState === 'signOut') {
      reset()
    }
  }, [gAuth.loginState])

  return {
    getCreditCard,
    creditCardList,
    setCreditCardList,
    addCardSuccess,
    setAddCardSuccess,
    openCardModal,
    setOpenCardModal,
    openCardModal2,
    setOpenCardModal2,
  }
}
