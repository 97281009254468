import { useEffect, useState } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { API } from 'aws-amplify'

export const useAuthState = () => {
  const [loginMail, setLoginMail] = useState('')
  const [loginState, setLoginState] = useState('configured')
  const [loginSub, setLoginSub] = useState('')
  const [nickname, setNickname] = useState('')
  const [companiesList, setCompaniesList] = useState([])
  const getCompanies = () => {
    API.get('Attendance', `/`)
      .then((res) => {
        setCompaniesList(res)
      })
      .catch((err) => console.log(err))
  }

  // 監控登入，方便除錯，並且抓取登入中的mail用
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {

          setLoginMail(user.username)
          setNickname(user.attributes.nickname)

          setLoginSub(user.attributes.sub) //存取入退室系統的員工subUUID
          setLoginState('signIn')
          // getCompanies()
        } else {
          setLoginState('signOut')
          setCompaniesList([])
        }
      })
      .catch(() => {
        setLoginState('signOut')
      })
  }, [loginState])

  useEffect(() => {
    Hub.listen('auth', (data) => {
      // console.log('Hub', data.payload.event)
      setLoginState(data.payload.event)
    })
  }, [])
  useEffect(() => {
    if (loginState === 'signOut') {
      setCompaniesList([])
    }
  }, [loginState])

  return {
    loginMail,
    setLoginMail,
    loginState,
    companiesList,
    setCompaniesList,
    getCompanies,
    nickname,
    loginSub,
  }
}
