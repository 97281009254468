import {API, Auth, Hub, PubSub} from "aws-amplify";
import {AWSIoTProvider, CONNECTION_STATE_CHANGE} from "@aws-amplify/pubsub";
import { useState} from "react";
import AWS from 'aws-sdk';
import {Buffer} from "buffer"


import CryptoJS from "crypto-js/core";
const randomTag = ( secret) => {
    const date = Math.floor(Date.now() / 1000)
    const dateDate = Buffer.allocUnsafe(4)
    dateDate.writeUInt32LE(date)
    const message = Buffer.from(dateDate.slice(1, 4))
    const msg = CryptoJS.enc.Hex.parse(message.toString('hex'))
    //   const msg = CryptoJS.enc.Hex.parse("c40e65")
    const key = CryptoJS.enc.Hex.parse(secret)
    const randomTag = CryptoJS.CMAC(key, msg)
    return  randomTag.toString().substring(0,8)
}
function isLock(hex) {

    try {
        let bytes = [];
        for (let i = 0; i < hex.length - 1; i += 2) {
            bytes.push(parseInt(hex.substr(i, 2), 16));
        }
        let bytes2 = new Uint8Array(bytes);
        let c = bytes2[bytes2.length - 1]
        return (c & 2) > 0 ? true : false
    }catch (e){}
    return  false
}

function toHexString(byteArray) {
    return Array.from(byteArray, function (byte) {
        return ('0' + (byte & 0xFF).toString(16)).slice(-2);
    }).join('')
}
function hexStringToByteArray(hexString) {
    let byteArray = [];
    for (let i = 0; i < hexString.length; i += 2) {
        byteArray.push(parseInt(hexString.substr(i, 2), 16));
    }
    return new Uint8Array(byteArray);
}

function toUInt24ByteArray() {
    let timestamp = new Date().getTime();
    let tmp = Math.floor(timestamp / 1000);
    let bytes = new Uint8Array(3);
    bytes[2] = (tmp >> 8) & 0xFF;
    bytes[1] = (tmp >> 16) & 0xFF;
    bytes[0] = (tmp >> 24) & 0xFF;
    return reverseUint8Array(bytes);
}

function reverseUint8Array(uint8Array) {
    let length = uint8Array.length;
    let reversed = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        reversed[i] = uint8Array[length - 1 - i];
    }
    return reversed;
}

function callData(call,shadowData){
    var   mlock=isLock(shadowData.mechst)

    let  isContails=false

    for (let keyValue of Object.entries(shadowData.wm2s ? shadowData.wm2s : {})) {

        if (keyValue[1] === '01') {
            isContails=true
        }
    }
   if (call) call( isContails?mlock:null)

}


export const useIotCtrl = (gAuth) => {
    const [subscribes, setsubscribes] = useState([])
    const [isInit, setisInit] = useState(false)
    const [isAuth, setisAuth] = useState(false)



    const initIot=()=>{

       // console.log("iotlog","isinit",isInit,isAuth)

        if (!isInit){

            PubSub.addPluggable(
                new AWSIoTProvider({
                    aws_pubsub_region: 'ap-northeast-1',
                    aws_pubsub_endpoint: 'wss://a3i4hui4gxwoo8-ats.iot.ap-northeast-1.amazonaws.com/mqtt'
                })
            )
            setisInit(true)

        }


    }
    const sub = (divcesId, call) => {
        initIot()
        if (!isAuth){
            Auth.currentCredentials().then((info) => {
                API.post('Attendance', `/device/addpolicy`, {
                    body:{
                        id:info.identityId
                    }
                })
                    .then((res) => {
                        setisAuth(true)
                        subrestart(divcesId,call)
                    })
                    .catch((err) => {})
            });

        }else {
            subrestart(divcesId,call)
        }

    }
    const  subrestart=(divcesId, call)=>{
        let topic = `$aws/things/sesame2/shadow/name/${divcesId}/update/accepted`
        const sub = PubSub.subscribe(topic, {provider: 'AWSIoTProvider'}).subscribe({
            next: data => {

                callData(call,data.value.state.reported)
            //    if (call) call(isLock(data.value.state.reported.mechst))
            },
            error: error => console.error('subrestart','Error during subscription', error),
            complete: () =>      console.log('subrestart',"complete"),
        });
        setsubscribes(prevSubscribes => [...prevSubscribes, sub]);
    }
    const unsub = () => {
        subscribes.forEach(sub => {
            sub.unsubscribe(); // 或者你的取消订阅的方法
        });

        setsubscribes([]);



    }

    const mIot = () => {
        const identityPoolId = 'ap-northeast-1:0a1820f1-dbb3-4bca-9227-2a92f6abf0ae';
        AWS.config.region = 'ap-northeast-1';
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: identityPoolId,
        });
        return new AWS.IotData({endpoint: 'a3i4hui4gxwoo8-ats.iot.ap-northeast-1.amazonaws.com'})
    }


    const getShadow = (deviceId, call) => {
        const thingName = 'sesame2';
        const shadowName = deviceId.toUpperCase(); // Assuming this is the equivalent of ssm2.deviceId.toString().uppercase()

        mIot().getThingShadow({thingName: thingName, shadowName: shadowName}, function (err, data) {
            if (err) {



            } else {
                const shadowData = JSON.parse(data.payload)['state']['reported'];
                let connectedWM2KeyValue;


                for (let keyValue of Object.entries(shadowData.wm2s ? shadowData.wm2s : {})) {
                    if (keyValue[1] === '01') {
                        connectedWM2KeyValue = keyValue;

                        if (call) call(shadowName, connectedWM2KeyValue)
                    }
                }

            }
        });
    }

    const getShadowSatus = (deviceId, call) => {
        const thingName = 'sesame2';
        const shadowName = deviceId.toUpperCase(); // Assuming this is the equivalent of ssm2.deviceId.toString().uppercase()
        mIot().getThingShadow({thingName: thingName, shadowName: shadowName}, function (err, data) {
            if (err) {
            } else {
                const shadowData = JSON.parse(data.payload)['state']['reported'];
                callData(call,shadowData)

            }
        });
    }
    const changeSwitch = (deviceId,sescretKey) => {
        const sign = randomTag( sescretKey)


        getShadow(deviceId, (shadowName, data) => {
            const cmdData = new Uint8Array(1);
            cmdData[0] = 88;
            let signBytes = toUInt24ByteArray()

            //    let signData = new Uint8Array("0fdd4ee0");

            let signData = Buffer.from(sign, 'hex').slice(0, 4);
            let historyTagData = history_tag_parser();

            let payload = new Uint8Array(signData.length + cmdData.length + shadowName.length + historyTagData.length);
            payload.set(signData);
            payload.set(cmdData, signData.length);
            payload.set(new TextEncoder().encode(shadowName), signData.length + cmdData.length);
            payload.set(historyTagData, signData.length + cmdData.length + shadowName.length);

            const params = {
                topic: "wm2".concat(data[0], "cmd"),
                payload: payload,
                qos: 0
            };
            mIot().publish(params, function (err, data) {
                if (err) {

                } else {

                }
            });
        });

    }
    const Sesame2HistoryLockOpType = {
        "BLE": 0,
        "WM2": 1,
        "WEB": 2,
        "BASE": 30
    };
    const history_tag_parser = function () {
        const b22 = new Uint8Array(22);
        try {
            //     let history_tag = Uint8Array.from(atob(body_history), c => c.charCodeAt(0));
            let encoder = new TextEncoder(); // 创建一个TextEncoder对象
            let history_tag = encoder.encode(gAuth.nickname); // 将字符串转换为字节数组
            let resourceCode = Sesame2HistoryLockOpType.BASE * Sesame2HistoryLockOpType.WM2;
            let history_first_int8 = resourceCode + history_tag.length;
            const history_first_byte = new Uint8Array(1);
            history_first_byte[0] = history_first_int8;
            b22.set(history_first_byte);
            b22.set(history_tag, history_first_byte.length);
        } catch (error) {
        }
        return b22;
    };


    return {
        sub,
        unsub,
        getShadowSatus,
        changeSwitch,

    }
}
