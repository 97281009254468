import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
export const useStripeInfo = (gAuth) => {
  const initCustomer = {
    id: null,
    invoice_settings: { default_payment_method: null },
  }
  const initSubscription = {
    items: { data: [{ plan: { id: null } }] },
    metadata: { downgradePlan: null },
    status: '',
  }
  const initInvoiceComing = {
    totalExcludingTax: 0,
    total: 0,
  }
  const [isPending, setIsPending] = useState(false)
  const [customer, setCustomer] = useState(initCustomer)
  const [subscription, setSubscription] = useState(initSubscription)
  const [usage, setUsage] = useState(0)
  const [invoiceComing, setInvoiceComing] = useState(initInvoiceComing)
  const [count, setCount] = useState(0)
  const item =
    typeof window !== 'undefined' ? localStorage.getItem('currLogin') : null

  const getUsage = (subscriptionID) => {
    API.get('Attendance', `/usage`, {
      queryStringParameters: { subscriptionID },
    })
      .then((res) => {
        setUsage(res)
      })
      .catch((err) => console.log(err))
  }

  const getInvoiceComing = (customer) => {
    API.get('Attendance', `/invoice`, {
      queryStringParameters: { customer },
    })
      .then((res) => {
        setInvoiceComing(res)
      })
      .catch((err) => console.log(err))
  }

  const getStripeInfo = (stripeInfo) => {
    setIsPending(true)
    API.get('Attendance', `/stripe_info`, {
      queryStringParameters: { stripeInfo },
    })
      .then((res) => {
        setCustomer(res.customer)
        setSubscription(res.customer.subscriptions.data[0])
        getUsage(res.customer.subscriptions.data[0].id)
        getInvoiceComing(res.customer.id)
        if (typeof window !== 'undefined') {
          localStorage.setItem('currLogin', res.customer.id)
          localStorage.setItem('currAccountName', res.customer.name)
        }
        if (gAuth.companiesList.length === 0) {
          gAuth.getCompanies()
        }
        setIsPending(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const reset = () => {
    setCustomer(initCustomer)
    setSubscription(initSubscription)
    setIsPending(false)
    setUsage(0)
    setCount(0)
  }

  useEffect(() => {
    if (gAuth.loginState === 'signOut') {
      reset()
    }
  }, [gAuth.loginState])

  useEffect(() => {
    // 頁面刷新，依據登入的使用者再渲染一次(真的有用)
    if (item) {
      getStripeInfo(item)
    }
  }, [item])

  return {
    customer,
    setCustomer,
    subscription,
    setSubscription,
    getStripeInfo,
    isPending,
    getUsage,
    usage,
    getInvoiceComing,
    invoiceComing,
    count,
    setCount,
  }
}
