import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'

export const useLoginEmployee = (gStripe, gAuth) => {
  const initAccess = { access: [], tag: [], companyID: undefined }
  const [loginInfo, setLoginInfo] = useState(initAccess)
  const [isPending, setIsPending] = useState(false)
  const getEmployeeInfo = (companyID) => {
    setIsPending(true)
    API.get('Attendance', `/employee/info`, {
      queryStringParameters: {
        companyID,
        subUUID: gAuth.loginSub,
      },
    })
      .then((res) => {
        res === '' ? setLoginInfo(initAccess) : setLoginInfo(res)
        setIsPending(false)
      })
      .catch((err) => console.log(err))
  }

  const reset = () => {
    setLoginInfo(initAccess)
    setIsPending(false)
  }

  useEffect(() => {
    if (gStripe.customer.id) {
      getEmployeeInfo(gStripe.customer.id)
    }
  }, [gStripe.customer.id])

  useEffect(() => {
    if (gAuth.loginState === 'signOut') {
      reset()
    }
  }, [gAuth.loginState])

  return {
    loginInfo,
    setLoginInfo,
    isPending,
  }
}
